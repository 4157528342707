<template>
  <div class="newDocumento" style="height:100%;">
    <v-layout justify-center align-center style="height:100%;">
      <v-flex xs11 md8 lg5>
          <v-toolbar color="secondary">
              <v-toolbar-title class="white--text">
                  Nuovo Documento
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn small icon @click="$router.back()" color="white" class="ml-3 mr-1">
                <v-icon small>fas fa-times</v-icon>
              </v-btn>
          </v-toolbar>
          <v-card outlined>
              <v-form v-model="formValid" ref="form_data" lazy-formValidation>
                  <v-container>
                      <v-layout column>
                          <v-text-field
                              autocomplete="off"
                              label="Titolo"
                              v-model="titolo"
                              required
                              :rules="testoRules"
                          ></v-text-field>
                          <v-file-input
                              accept=".pdf,.png,.jpg,.jpeg" 
                              label="Documento"
                              v-model="documento"
                              :rules="documentoRules"
                              prepend-inner-icon="fas fa-paperclip"
                              prepend-icon=""
                              truncate-length="25"
                          ></v-file-input>
                          <v-menu
                            ref="menuDataScadenza"
                            v-model="menuDataScadenza"
                            :close-on-content-click="false"
                            :return-value.sync="dataScadenza"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                autocomplete="off"
                                v-model="dataScadenza"
                                label="Data Scadenza"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="dataScadenza"
                              no-title
                              scrollable
                              locale="it"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="menuDataScadenza = false"
                              >
                                Chiudi
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.menuDataScadenza.save(dataScadenza)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                          <v-textarea
                              autocomplete="off"
                              label="Note"
                              v-model="note"
                              outlined
                              auto-grow
                              rows="1"
                          ></v-textarea>
                          <v-select
                              :items="lista_permessi"
                              v-model="permessi"
                              multiple
                              required
                              filled
                              :rules="requiredRules"
                              label="Permessi"
                              class="mb-0"
                          ></v-select>
                          <v-select
                              v-if="permessi.indexOf('Cliente') !== -1"
                              :items="lista_clienti"
                              v-model="cliente"
                              outlined
                              label="Cliente"
                              class="mb-0"
                              no-data-text="Nessun Cliente"
                              item-text="nominativo1"
                              item-value="_id"
                          ></v-select>
                      </v-layout>
                  </v-container>
              </v-form>
              <v-card-actions>
                <v-container grid-list-sm fluid>
                  <v-row>
                    <v-col cols="12" md="4" offset-md="4" sm="12" class="pt-0">
                      <v-btn color="primary" width="100%" @click="submit" :disabled="!formValid || loading">Aggiungi</v-btn> 
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
          </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import apiUtenti from '@/js/pages/utenti';
import apiDocumenti from '@/js/pages/documenti';
import { testoRules, requiredRules, documentoRules } from '@/js/validationRules';

export default {
  name: 'newDocumento',
  data() {
    return {
      token: this.$cookies.get('token'),
      titolo: '',
      note: '',
      permessi: [],
      cliente: undefined,
      stabilimento: undefined,
      documento: undefined,
      menuDataScadenza: false,
      dataScadenza: new Date().toISOString().substr(0, 10),
      lista_clienti: [],
      lista_stabilimenti: [],
      formValid: false,
      testoRules: testoRules,
      requiredRules: requiredRules,
      documentoRules: documentoRules,
      loading: false,
      lista_permessi: ['Master', 'Tecnico', 'Partner', 'Cliente'],
    }
  },
  async created() {
    this.lista_clienti = await apiUtenti.getAll(this.token, 'Cliente');
  },
  methods: {
    async submit(){
      this.loading = true;
      if(
        this.$refs.form_data.validate() &&
        this.formValid 
      ){
        let data = {
          titolo: this.titolo,
          note: this.note,
          data_scadenza: this.dataScadenza,
          id_cliente: this.cliente,
          id_stabilimento: this.stabilimento,
          permessi: this.permessi
        };
        let id_documento = await apiDocumenti.insertNew(this.token, data);
        await this.sendFile(id_documento);
        this.$router.push('/listDocumenti');
      }
      this.loading = false;
    },
    async sendFile(_id) {
      if(this.documento) {
        let formData = new FormData();
        formData.append("documento", this.documento);
        return await apiDocumenti.sendFile(this.token, _id, formData);
      } else return false;
    },
  },
}
</script>
